@import "normalise"; // Standardise elements
@import "lightcase";
@import "slick";
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,700');

// Vars
$body-background: #f2f2f2; // off-white
$body-color: #333; // Drak grey
$nav-line-height: 70px;
$nav-break-point: 800px;
$promo-break-point: $nav-break-point;

$spot-color: #448cca;

$link-color: $spot-color;
$main-header-bg-color: white;
$hero-bg-color: silver;
$promo-section-bg-color: none;
$promo-bg-color: none;
$promo-content-bg-color: none;


#player {
  background: black !important;
}
// Marketing Foundation v1.0

html {
  font-size:62.5%; // Easy rem values
  height:100%; // May muck-up stuff!

  // &.locked {
  //   width: 100%;
  //   height: 100%;
  //   position: fixed;
  //   overflow: hidden;
  // }

}

body {
  background:$body-background;
  color:$body-color;
  font-family:'Open Sans', Helvetica, Arial, sans-serif;
  font-weight:400;
  font-size: 1.4rem;
  line-height:1.5em;

  &.locked {
    overflow: hidden;
    position: relative;
  }

  &.fixed-nav {

    .hero {
      padding-top:$nav-line-height;
    }

    .main-header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }

  }

  // &.locked {
  //   width: 100%;
  //   height: 100%;
  //   position: fixed;
  //   overflow: hidden;
  // }

}

textarea, input {outline:none;} // remove webkit glow

* {
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

// Modern clearfix hack http://css-tricks.com/snippets/css/clear-fix/ for IE8+
.cf-group:after {
  content:"";
  display:table;
  clear:both;
}

a {
  color: $link-color;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

.larger {
  font-size: 2.6rem;
}

.smaller {
  font-size: 1.8rem;
}

.small {
  font-size: 10px;
}

hr {
  border: 0;
  width: 100%;
  height: 1px;
  background: silver;
}

textarea, input {outline:none;} // remove webkit glow

textarea, input {
  width: 100%;
  display: block;
  margin: 16px 0;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 1.2rem 1.2rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-appearance: none; // careful if we want to use radio button or check-box!

  &.error {
    border-color: tomato;
  }

  &:focus {
    border-color: $spot-color;
  }

}

textarea {
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  resize: none;
}

.button {
  display: inline-block;
  min-width: 270px;
  border: 2px solid $spot-color;
  text-transform: uppercase;
  color: white;
  padding: 10px;
  margin: 16px 0;
  background: rgba(0,0,0,0.25);
  transition: background 0.3s, border-color 0.3s;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-align: center;

  &:hover {
    background: rgba(0,0,0,0.5);
    border-color: darken($spot-color, 10%);
  }

  &.clear {
    background: transparent;
  }

  &.solid {
    background: $spot-color;

    &:hover {
      background: darken($spot-color, 10%);
      border-color: darken($spot-color, 10%);
    }
  }

  .material-icons {
    path {
      fill:#ffffff;
    }
    margin-top: -3px;
    vertical-align: middle;
  }

}



.disc {
  display: inline-block;
  width: 44px;
  height: 44px;
  background: $spot-color;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  text-decoration: none;
  font-size: 24px;
  color: white;
}

#message-box {
  display:none;
  background:white;
  z-index:99999;
  position:fixed;
  top:0;
  width:100%;
  padding:8px;
  color:$spot-color;
  text-align:center;
  -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.5);
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.5);

  .title {
    font-weight:700;
    display:block;
  }

  .content {
    font-size:14px;
  }

  &.no {
    background:$spot-color;
    color:white;
  }

  @media only screen and (min-width:800px) {
    padding:16px;

    .title {
      display:inline;
    }

  }

}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.8rem;
}

.bg-img, .bg-img.promo-section {
    background:transparent url(assets/#) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

    .wrapper {
      position: relative;
      z-index: 10;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,0.5);
    }

    .promo-block {
      position: relative;
      z-index: 10;
    }


}

.platformiOS .bg-img, .platformiOS .bg-img.promo-section, .platformAndroid .bg-img, .platformAndroid .bg-img.promo-section {
  background-attachment: scroll;
}

.main-header {
  width: 100%;
  background: $main-header-bg-color;
  -webkit-box-shadow: 0 1px 3px 3px rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px 3px rgba(0,0,0,0.1);
  // border-top: 5px solid $spot-color;

  .wrapper {
    content:"";
    display:table;
    clear:both;
  }

  .brand {
    width: 250px;
    height: 70px;
    line-height: 70px;
    background: transparent url(assets/logo-header.png) no-repeat center left;
    display: inline-block;
    float: left;
    color: #333;
    font-family: 'Roboto';
    font-size: 30px;
    text-indent: -9999em;
    overflow: hidden;
  }

}

.main-nav {
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.75);

  @media only screen and (min-width:$nav-break-point) {
    position: relative;
    background: transparent;
    display: inline-block;
    width: auto;
    float: right;
  }

  ul {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    background: rgba(255,255,255,0.75);
    margin: 0;
    padding: 0;
    list-style-type: none;
    -webkit-box-shadow: -1px 0 5px 0 rgba(0,0,0,0.15);
    box-shadow: -1px 0 5px 0 rgba(0,0,0,0.15);
    z-index: 10;
    -ms-transform: translate(280px,0); /* IE 9 */
    -webkit-transform: translate(280px,0); /* Safari */
    transform: translate(280px,0);
    -webkit-transition: translate 0.3s;
    transition: translate 0.3s;

    @media only screen and (min-width:$nav-break-point) {
      display: block;
      position: relative;
      width: auto;
      height: auto;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;
      -ms-transform: translate(0,0); /* IE 9 */
      -webkit-transform: translate(0,0); /* Safari */
      transform: translate(0,0);
    }

    li {

      @media only screen and (min-width:$nav-break-point) {
        display: inline-block;
      }

      a {
        display: block;
        width: 100%;
        line-height: $nav-line-height;
        padding: 0 1.6rem;
        text-decoration: none;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        color: darken($spot-color, 10%);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        transition: color 0.3s;

        &:hover {
          color: lighten($spot-color, 5%);
        }

        @media only screen and (min-width:$nav-break-point) {
          border: none;
        }

      }

    }

  }

}

.small-menu-trigger {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  text-decoration: none;
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -15px;

  .icon {
    vertical-align: middle;
    line-height: 29px;
  }

  @media only screen and (min-width:$nav-break-point) {
    display: none;
  }
}

.navOpen {
  overflow:hidden;

  @media only screen and (min-width:$nav-break-point) {
    overflow: scroll;
  }

  .navSmoker {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.75);

    @media only screen and (min-width:$nav-break-point) {
      display: none;
    }

  }

  .main-nav {

    ul {
      display: block;
      -ms-transform: translate(0,0); /* IE 9 */
      -webkit-transform: translate(0,0); /* Safari */
      transform: translate(0,0);
    }

  }

}

.promo-section {
  position: relative;
  background:$promo-section-bg-color;
  padding: 40px 0;
}

.promo-block {
  width:100%;

  &:after {
    content:"";
    display:table;
    clear:both;
  }

  .promo {
    width: 100%;
    float:left;
    background: $promo-bg-color;
    padding:0.8rem;

    h2 {
      margin:0;
      line-height: 1.5em;
    }

    .promo-content {
      background: $promo-content-bg-color;
      padding: 0.8rem;

      .promo-copy {

        img {
          width: 100%;
          display: block;
        }

        p {
          color: lighten($body-color, 35%);
        }

      }

    }

    &.center {
      text-align: center;

      .disc {
        display: block;
        margin: 0 auto;
        margin-bottom: 0.8rem;
      }

      .promo-content {
        max-width: 700px;
        margin: 0 auto;
      }

    }

  }

  @media only screen and (min-width:$promo-break-point) {

    &.two {

      .promo {
        width:50%;
      }

    }

    &.three {

      .promo {
        width:33.333333%;
      }

    }

  }

  &.flip, &.flop {

    .promo-content {
      content: "";
      display: table;
      clear: both;
      width: 100%;
    }

    .promo-img {
      padding-bottom: 1.6rem;
      width: 100%;
    }

    @media only screen and (min-width:$promo-break-point) {

      .promo-img, .promo-copy {
        width: 50%;
      }

      .promo-img {
        padding: 0;
      }

    }

  }

  &.flip {

    .promo-img, .promo-copy {
      float:left;
    }

    .promo-copy {
      padding-left: 0.8rem;
    }

  }

  &.flop {

    .promo-img, .promo-copy {
      float:right;
    }

    .promo-copy {
      padding-right: 0.8rem;
    }

  }

}

.follow-section {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;

  h2 {
    line-height: 1.5em;
  }

  hr {
    margin: 3.6rem 0;
  }
}

.slider {
  margin: 0 auto;
  max-width: 870px;
  padding: 0 40px;
  color: white;

  blockquote {
    display: none;
    margin: 20px 10px;
  }

  cite {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    font-style: normal;
  }

  .slick-prev, .slick-next {
    position: absolute;
    background: transparent;
    top:15px;
    bottom:15px;
    width:40px;

    .material-icons {
      font-size: 48px;
      position: absolute;
      top:50%;
      right:0;
      margin-top:-24px;
      color: $spot-color;
      cursor: pointer;
    }

  }

  .slick-prev {
    left:0;
  }

  .slick-next {
    right:0;

    .material-icons {
      right:auto;
      left:0;
    }

  }

  .slick-slide {

    blockquote {
      display: block;
    }

  }

  .slick-dots {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        position: relative;
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -4px;
          margin-top: -4px;
          width: 8px;
          height: 8px;
          content: '\2022';
          opacity: .5;
          background: white;
          border-radius: 50%;
        }

      }

    }

    li.slick-active {

      button:before {
        opacity:1;
        background: $spot-color;
      }

    }

  }

}

.contact-info {
  margin:0 auto;
  max-width: 100%;
  position: relative;

  @media only screen and (min-width:540px) {
    padding-right: 300px;
  }

  @media only screen and (min-width:950px) {
    padding-right: 584px;
  }


  ul {
    width: 100%;
    text-align: left;
    list-style-type: none;
    background: red;
    padding:1.6rem;
    padding-bottom: 0;
    line-height: 1.5em;
    margin:0;
    color:#eee;
    height: 233px;

    @media only screen and (min-width:540px) {
      width:300px;
      position: absolute;
      top:0;
      right:0;
    }

    @media only screen and (min-width:950px) {
      width:584px;
    }

    li {

      &.break {
        height:6px;
        width:100%;
      }

      &.social-icons {
        text-align: right;
      }

    }

  }

  .map {
    display:block;
    width:100%;
    height:233px;
    max-width:640px;
    background:darken(#eee, 10%) url('//maps.googleapis.com/maps/api/staticmap?center=OX447RW&zoom=12&size=640x400&scale=1&markers=color:0xfe8b36|OX447rw&key=AIzaSyDIambEhYVmDDPgdkUEChFirJCxslOdBTE') no-repeat center center;
  }

}

.main-footer {
  text-align: center;
  background: #333;
  color: #999;
  padding: 0.8rem;
}

// Modal Start
.smoker {
  display:block;
  position: fixed;
  top:-100%;
  left:0;
  width:100%;
  height:100%;
  padding:0.4rem;
  z-index:9999;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  background:rgba(0,0,0,0.75);

  @media only screen and (min-width:600px) {
    padding:1rem;
  }

  &:hover {
    // cursor: pointer;
  }

  .modal {
    top:-100%;
    display: block;
    z-index:999;
    padding:0.8rem;
    position: relative;

    @media only screen and (min-width:600px) {
      padding:1.6rem;
    }

    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background:white; // #292827;
    color: #292827;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;

    &:hover {
      cursor: initial;
    }

    a {
      // color: #292827;
      //border-color:rgba(0,0,0, 0.65);

      &:hover {
        // border-color: black;
      }
    }

    ul {
      padding-left: 2rem;
    }

    li {
      margin-bottom: 1.6rem;
    }

    .closer {

      &.icon {
        font-size:30px;
        position:absolute;
        top:5px;
        right:5px;
        text-align: center;
        line-height: 35px;
        text-decoration: none;
        // background:#333;
        color:$spot-color;
        // opacity:0.75;
        border-radius: 50%;
        width: 35px;
        height: 35px;

        -webkit-transition:color 0.3s;
        transition:color 0.3s;

        &:hover {
          color:$spot-color;
          cursor:pointer;
        }

        &:after {
          content:'\00d7';
        }
      }

    }

    .button.buttonSend {
      margin-top: 0.8rem;
      margin-bottom: 1.6rem;
      outline: none;
      background: transparent;
      color: $spot-color;
      text-align: center;
      width: 100%;
      min-width: 20px;
    }

    &.block {

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.5);
      }

      .button.buttonSend {
        position: relative;

        &:after {
          content:'';
          position: absolute;
          z-index: 99;
          top: 50%;
          right: 50%;
          text-indent: -9999em;
          border-top: 2px solid rgba(255, 255, 255, 0.2);
          border-right: 2px solid rgba(255, 255, 255, 0.2);
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
          border-left: 2px solid $spot-color;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation: cssspinner 0.8s infinite linear;
          animation: cssspinner 0.8s infinite linear;
          width: 30px;
          height: 30px;

          margin-top: -17px;
          margin-right: -17px;
          border-radius: 50%;
        }

      }

    }

  }

  &.on {
    top: 0;

    .modal {
      opacity: 1;
      top: 0;
    }

  }

  &.off {
    top: -100%;

    .modal {
      opacity: 0;
    }
  }

}

@-webkit-keyframes cssspinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes cssspinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Marketing Foundation v1.0 - END


// Site Specific

#hero {
  background-color: $hero-bg-color;
  background-image: url(//res.cloudinary.com/squidge-inc/image/fetch/f_auto,w_1500/https://trakd.co.uk/assets/6B70C91911.jpg);
  padding:150px 0 100px 0 !important;
  text-align: center;

  @media only screen and (min-width:$promo-break-point) {
    padding:200px 0 120px 0 !important;
  }

  h1 {
    margin:0;
    margin-top: 0.8rem;
    color: #f3f3f3;
    line-height: 1.5em;
    font-size: 2rem;
  }

  img {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
  }

  .button:last-of-type {
    margin-top: 0;
    min-width: 200px;
  }

}

#problem {
  background: white;
}

#what {
  background-image: url(//res.cloudinary.com/squidge-inc/image/fetch/f_auto,w_1500/https://trakd.co.uk/assets/TRAKD_01_793A1064.jpg); // 935D24BC53.jpg

  .promo-block {
    padding: 50px 0;

    @media only screen and (min-width:$promo-break-point) {
      padding: 150px 0;
    }

    .promo-copy {
      color: white !important;

      p {
        color: white;
      }

    }

  }
}

#features {
  background: white;

  .promo-copy {
    max-width: 500px;
    margin: 0 auto;

    p {
      margin-top: 0.8rem;
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
    }

  }

}

#testimonials {
  background-image: url(//res.cloudinary.com/squidge-inc/image/fetch/f_auto,w_1500/https://trakd.co.uk/assets/211C1CBF50.jpg); // TRAKD_01_793A1088.jpg

  .promo-block {
    padding: 30px 0;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width:$promo-break-point) {
      max-width: 100%;
      display: flex;
      align-items: stretch;
    }
  }

  .testimonial-featured {
    width: 100%;
    padding-bottom: 100%;
    background: transparent url(#) no-repeat center center;
    background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(//res.cloudinary.com/squidge-inc/image/fetch/f_auto,w_672/https://trakd.co.uk/assets/testimonials-bg.png);
    background-size: cover;
    position: relative;

    .button {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -135px;
      margin-top: -23px;
    }

    @media only screen and (min-width:$promo-break-point) {
      padding-bottom: 0;
      width: 50%;
      max-width: 100%;
      height: auto;
    }
  }

  .testimonial-quotes {
    width: 100%;
    padding: 2.4rem 1.6rem;
    background: rgba(0,0,0,0.5);
    color: white;
    display: flex;
    min-height: 500px;

    @media only screen and (min-width:520px) {
      padding: 2.4rem;
    }

    @media only screen and (min-width:$promo-break-point) {
      width: 50%;
      min-height: 59.2rem;
    }

    .quote-slider {
      margin: auto;
      width: 100%;

      .quotes {
        width: 100%;
      }

      p {
        font-size: 1.8rem;
        line-height: 1.5;
        font-weight: 300;
      }

      & p:first-of-type {
        margin-top: 0;
      }

      blockquote {
        margin: 0;
      }

      cite {
        display: block;
        text-align: right;
        font-weight: normal;
        font-size: 1.0rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        span {
          display: inline-block;
        }
      }
    }
  }

}

.platformiOS #testimonials, .platformAndroid #testimonials {
  background: #333333;
}

.slider {
  text-align: center;
}

#contact {
  background: white;

  .button.clear {
    color: $spot-color;
  }
}
